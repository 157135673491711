import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { graphql, Link } from 'gatsby';
import Tabs from '@material-ui/core/Tabs';
import ReactMarkdown from 'react-markdown';
import { format } from 'date-fns';

import Layout from 'components/layout';
import { device } from 'components/device';
import FeaturedMarketing from 'components/featuredMarketing';
import flatten from 'lodash.flatten';
import AcessoriaImprensa from 'components/AcessoriaImprensa';
import SEO from 'components/seo';

import { TabsContext } from 'context/tabs';

import shareImg from 'images/logos/logo-hsl-share.png';

const GridContainer = styled.div`
  ${props =>
    props.isDesktop
      ? `
    display: grid;
    grid-template-columns: auto repeat(12, 70px) auto;
    column-gap: 30px;
  `
      : `
    padding: 0 1.25rem;
    display: flex;
    flex-direction: column;
  `}
  :last-child {
    border-top: 1px solid #c6c8cc;
    padding: 50px 20px 70px;
    @media only screen and (max-width: 414px) {
      padding: 30px 20px 70px;
    }
  }
  > h2 {
    grid-column: 4 / span 6;
    text-align: left;
    font: normal normal bold 1.5rem/2.125rem;
    color: #231f20;
  }
  h6 {
    font: normal normal 300 1.875rem/2.5rem;
    color: #8f9194;
    grid-column: 4 / span 6;
    @media ${device.mobile} {
      font-size: 1.5rem;
    }
  }
`;
const Part = styled.div`
  grid-column: ${props => props.gridColumn};
  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: start;
    @media ${device.laptop} {
      margin-bottom: 1.875rem;
    }
    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }
  :last-child {
    margin-bottom: 0;
  }
  ${props =>
    props.isContent &&
    `
    a {
      color: #45a7df;
    }
    ul, ol {
      li {
        font-size: 1rem;
        color: #231f20;
        font-size: 1rem;
        line-height: 1.625rem;
        @media ${device.laptop} {
          font-size: 0.875rem;
          line-height: 1.376rem;
        }
      }
    }
  `}
  > p {
    font-size: 1rem;
    color: #231f20;
    font-size: 1rem;
    line-height: 1.625rem;
    padding-bottom: 1.875rem;
    @media ${device.laptop} {
      font-size: 0.875rem;
      line-height: 1.376rem;
    }
    > strong {
      color: #231f20;
      grid-column: 4 / span 6;
    }
    > em {
      color: #8f9194;
      font-size: 0.875rem;
      grid-column: 4 / span 6;
      font-style: normal;
    }
    img {
      width: 100%;
    }
  }
  > p:first-child {
    > em {
      display: flex;
      justify-content: center;
    }
  }
  section {
    border-bottom: none;
    margin-bottom: 0;
  }
  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  h2,
  h3 {
    font-weight: normal;
    color: #231f20;
  }
  h3 {
    font-size: 24px;
  }
  h2 {
    font-size: ${props =>
      props.typeOfPost == 'Boletins Médicos' ||
      props.typeOfPost == 'Notas de Falecimento'
        ? '16px'
        : '24px'};
    text-align: ${props =>
      props.typeOfPost == 'Boletins Médicos' ||
      props.typeOfPost == 'Notas de Falecimento'
        ? 'center'
        : 'left'};

    margin-bottom: ${props =>
      props.typeOfPost == 'Notas de Falecimento' && '5px'};
    margin-bottom: ${props => props.typeOfPost == 'Boletins Médicos' && '5px'};
  }
  pre {
    @media ${device.mobile} {
      width: 337px;
    }
    @media only screen and (max-width: 320px) {
      width: 280px;
    }
    @media only screen and (max-width: 414px) {
      width: 373px;
    }
    code {
      font-family: 'Inter';
    }
  }
  h3 {
    margin-top: 1.45rem;
  }
  p {
    font-size: 14px;
    line-height: 24px;
    color: #231f20;
    font-weight: normal;
    margin: 15px 0;
    a {
      color: #231f20;
      strong {
        color: #231f20;
      }
    }
    :nth-child(1) {
      margin-top: 0;
    }
  }
  @media ${device.mobile} {
    padding: 0 0;
  }
  section {
    @media ${device.mobile} {
      height: auto;
    }
  }
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  .active {
    border-bottom: 2px solid #45a7df;
    padding: 0 0.625rem 15px 0;
    display: block;
  }
  .MuiTabs-scroller {
    width: 90vw;
  }

  .MuiTabs-root {
    min-height: 20px;
  }
`;
const BoxContent = styled.div`
  display: flex;
  justify-content: ${props =>
    props.typeOfPost == 'Boletins Médicos' ||
    props.typeOfPost == 'Notas de Falecimento'
      ? 'center'
      : 'start'};
  flex-wrap: wrap;
  margin-bottom: 15px;
  span {
    color: #8f9194;
    line-height: 26px;
  }
  .dot {
    height: 2px;
    width: 2px;
    background-color: #8f9194;
    border-radius: 50%;
    display: inline-block;
    margin: 4px 3px;
    display: ${props =>
      props.typeOfPost == 'Boletins Médicos' ||
      props.typeOfPost == 'Notas de Falecimento'
        ? 'none'
        : 'inline-block'};
  }
`;
export const LinkToImprensa = styled(Link)`
  margin: 6px 26px 0 0;
  color: #231f20;
  font-size: 16px;
  cursor: pointer;
  :hover {
    color: #45a7df;
  }
`;

export const SubjectText = styled.span`
  ${props =>
    props.lastItem
      ? css`
          &::after {
            content: '';
          }
        `
      : css`
          &::after {
            content: ', ';
            margin-right: 0.25rem;
          }
        `}
`;
const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};
const renderPage = (
  page,
  markdown,
  title,
  isDesktop,
  typeOfPost,
  date,
  blogPressreleasesAssunto,
  tabs,
  setTabs,
  setActiveTabs,
  newData,
  newHora
) => (
  <>
    <GridContainer isDesktop={isDesktop}>
      <Part gridColumn={'2 / -2'}>
        <FeaturedMarketing
          markdown={getMarkdown(page, 'cktsqm3ts8gcr0b749yfy1qas', true)}
          // breadCrumb={getMarkdown(page, 'cktsomd9k2aes0c223qq0chib', true)}
          breadCrumb={`1. [Home](/) › [Imprensa](/imprensa/) › [${tabs}](/imprensa/) › ${title}`}
          isPatologica
          firstBlobTransformDesk="translate(166%,-20%) rotate(181deg)"
          firstBlobTransformDeskLarge="translate(166%,-20%) rotate(181deg)"
          firstBlobTransformLap="translate(217%,-17%) rotate(179deg)"
          firstBlobTransformMob="translate(176%,-15%) rotate(179deg)"
          secBlobTransformDesk="translate(-141%,79%) rotate(189deg)"
          secBlobTransformDeskLarge="translate(-141%,79%) rotate(189deg)"
          secBlobTransformLap="translate(-192%,96%) rotate(-16deg)"
          secBlobTransformMob="translate(-148%,152%) rotate(191deg)"
          containerMarginBottomDesk
          titleWidthLap="30rem"
          boxInfoTextWidthDesk="28.125rem"
          boxContentPaddingBottomDesk="6.25rem"
          boxInfoParagraphImgTransformDesk="translate(0, 27%)"
          boxInfoParagraphImgWidth="unset"
          boxInfoParagraphImgHeight="unset"
          titleWidthDesk="100%"
          boxInfoWidthDesk="70%"
          marginTopTitle="3rem"
          titleMarginLap="0"
          containerNoPaddingTopLap
          isTabs
        />
      </Part>
    </GridContainer>
    <GridContainer isDesktop={isDesktop}>
      <Part gridColumn={'2 / -2'}>
        <LinkContainer>
          <Tabs variant="scrollable" scrollButtons={false}>
            <LinkToImprensa
              to={'../'}
              onClick={() => setActiveTabs({ name: 'PressReleases', index: 0 })}
              className={tabs == 'Press-Releases' ? 'active' : ''}
            >
              Press-Releases
            </LinkToImprensa>
            <LinkToImprensa
              to={'../'}
              onClick={() =>
                setActiveTabs({ name: 'BoletinsMedicos', index: 1 })
              }
              className={tabs == 'Boletins Médicos' ? 'active' : ''}
            >
              Boletins Médicos
            </LinkToImprensa>
            <LinkToImprensa
              to={'../'}
              onClick={() =>
                setActiveTabs({ name: 'NotasDeFalecimento', index: 2 })
              }
              className={tabs == 'Notas de Falecimento' ? 'active' : ''}
            >
              Notas de Falecimento
            </LinkToImprensa>
          </Tabs>
        </LinkContainer>
      </Part>
    </GridContainer>
    <GridContainer isDesktop={isDesktop}>
      <Part gridColumn={'2 / -8'}>
        <Container typeOfPost={typeOfPost}>
          <h2>{title}</h2>
          <BoxContent typeOfPost={typeOfPost}>
            <span>
              {tabs == 'Press-Releases' && <span>{newData}</span>}
              <span className="dot"></span>
              {tabs != 'Press-Releases' && <span> {newData}</span>}
              {tabs != 'Press-Releases' && <span> às {newHora}</span>}
            </span>
            {blogPressreleasesAssunto?.map((item, i, arr) => (
              <SubjectText key={item.assunto} lastItem={i === arr.length - 1}>
                {item.assunto}
              </SubjectText>
            ))}
          </BoxContent>
          <ReactMarkdown children={markdown}></ReactMarkdown>
        </Container>
      </Part>
      <Part gridColumn={'9 / 12'}>
        <Container>
          <AcessoriaImprensa
            markdown={getMarkdown(page, 'cktsopcio85it0b74wakn5319', true)}
            fragments={getMarkdown(page, 'cktsopcio85it0b74wakn5319')}
            marginTop
            contentPostAtrr
            fontSize320
          ></AcessoriaImprensa>
        </Container>
      </Part>
    </GridContainer>
  </>
);
const ContentPost = ({
  data: {
    gcms: {
      posts,
      site: { pages },
    },
  },
  pageContext,
}) => {
  const { title, typeOfPost, date, blogPressreleasesAssunto } = pageContext;
  const markdown = posts?.[0]?.localizations[0]?.markdown[1]
    ? posts?.[0]?.localizations[0]?.markdown[1]
    : posts?.[0]?.localizations[0]?.markdown[0];
  const [isDesktop, setDesktop] = useState(true);
  const [handleTypeOfPost, sethandleTypeOfPost] = useState('');
  const { tabs, activeTabs, setActiveTabs, setTabs } = useContext(TabsContext);
  let newData = '';
  let newHora = '';

  if (posts[0]?.dataEHora && posts[0]?.dataEHora != undefined) {
    newData = format(new Date(posts[0]?.dataEHora), 'dd/MM/yyyy');
    newHora = format(new Date(posts[0]?.dataEHora), 'HH:mm');
  }
  useEffect(() => {
    typeOfPost == 'PressReleases'
      ? sethandleTypeOfPost('Press-Releases')
      : typeOfPost == 'BoletinsMedicos'
      ? sethandleTypeOfPost('Boletins Médicos')
      : sethandleTypeOfPost('Notas de Falecimento');
  }, []);

  useEffect(() => {
    typeOfPost[0] == 'PressReleases'
      ? setTabs('Press-Releases')
      : typeOfPost == 'BoletinsMedicos'
      ? setTabs('Boletins Médicos')
      : setTabs('Notas de Falecimento');
  }, []);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const titleSeo =
    posts?.[0]?.localizations[0]?.titleSeo ||
    pageContext?.title ||
    (pageContext?.localizations &&
      pageContext?.localizations[0].length > 0 &&
      pageContext?.localizations[0].title) ||
    'Postagem';

  const descriptionSEO = posts?.[0]?.localizations[0]?.descriptionSeo || '-';

  const dataSeo = [
    {
      seo: {
        metaTitle: titleSeo,
        metaDescription: descriptionSEO,
      },
    },
  ];

  return (
    <>
      <Layout>
        <SEO
          dataSeo={dataSeo}
          image={
            posts?.[0]?.localizations[0]?.ogImageSeo?.handle !== undefined
              ? `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${posts?.[0]?.localizations[0]?.ogImageSeo?.handle}`
              : shareImg
          }
        />
        {renderPage(
          pages,
          markdown,
          title,
          isDesktop,
          handleTypeOfPost,
          date,
          blogPressreleasesAssunto,
          tabs,
          activeTabs,
          setActiveTabs,
          newData,
          newHora
        )}
      </Layout>
    </>
  );
};
export const ContentPostQuery = graphql`
  query ContentPostQuery($id: ID) {
    gcms {
      site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
        pages(where: { id: "cktsok5sg83530b74e82jhx1d" }) {
          fragments {
            id
            name
            localizations {
              singletexts
              markdown
            }
            assetpicker {
              handle
              width
              height
            }
          }
        }
      }
      posts(where: { id: $id }) {
        id
        title
        slugPost
        markdown
        date
        typeOfPost
        dataEHora
        localizations {
          markdown
          date
          title
          date
          titleSeo
          descriptionSeo
          ogImageSeo {
            handle
            width
            height
          }
        }
      }
    }
  }
`;
export default ContentPost;
